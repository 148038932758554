<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-card>
          <v-subheader class="py-0 px-0">
            <v-switch
              class="ml-1"
              v-model="generarCodigoAutomaticamente"
              dense
              outlined
            ></v-switch>
            Generar código de proveedor automáticamente
          </v-subheader>
        </v-card>
        <v-col cols="12" class="pb-0 px-0">
          <v-btn
            color="primary"
            class="to-right"
            :loading="loadingSaveBtn"
            @click="saveParametrosGenerales()"
            >Actualizar</v-btn
          >
        </v-col>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-0 pr-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import Ayuda from "@/components/shared/Ayuda.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import { mapActions } from "vuex";

export default {
  name: "ParametrosGeneralesProveedores",
  components: {
    PageHeader,
    GoBackBtn,
    Ayuda
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.getParametrosGenerales();
  },
  data() {
    return {
      title: "Parámetros generales",
      showExpand: false,
      showHelp: false,
      optionCode: enums.webSiteOptions.PARAMETROS_GENERALES_PROVEEDORES,
      showIcon: true,
      routeToGo: "ConfiguracionTablasProveedores",
      loadingSaveBtn: false,
      generarCodigoAutomaticamente: false
    };
  },
  methods: {
    ...mapActions({
      getParametrosGeneralesProveedores:
        "proveedores/getParametrosGeneralesProveedores",
      saveParametrosGeneralesProveedores:
        "proveedores/saveParametrosGeneralesProveedores",
      setAlert: "user/setAlert"
    }),
    async getParametrosGenerales() {
      const response = await this.getParametrosGeneralesProveedores();
      this.generarCodigoAutomaticamente = response.generaCodProv;
    },
    async saveParametrosGenerales() {
      this.loadingSaveBtn = true;
      const data = {
        generaCodProv: this.generarCodigoAutomaticamente
      };
      try {
        const response = await this.saveParametrosGeneralesProveedores(data);
        if (response.status == 200) {
          this.setAlert({ type: "success", message: "Actualizado con éxito." });
          this.getParametrosGenerales();
        }
      } catch {}
      this.loadingSaveBtn = false;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style></style>
